import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../../components/ExternalLink';
import Playground from '../../../../components/Playground';
import ContrastExample from '../../../../components/examples/ContrastExample';
import FocusExample from '../../../../components/examples/FocusExample';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/colour/accessibility",
  "title": "Colour tokens - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "things-to-note",
      "style": {
        "position": "relative"
      }
    }}>{`Things to note`}<a parentName="h3" {...{
        "href": "#things-to-note",
        "aria-label": "things to note permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`When choosing and using colours, always check that sufficient colour contrast between the element and its background is maintained. All colour choices should comply with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`WCAG 2.1 Standard at AA Level contrast ratios`}</ExternalLink>{`.`}</li>
      <li parentName="ul">{`Do not rely solely on colour when communicating information to the user – especially crucial system information like error state, success state, or warnings. Also, convey the same information with another modality, e.g. with a text label or icon that clearly indicates its meaning.`}</li>
    </ul>
    <h3 {...{
      "id": "accessible-colour-combinations",
      "style": {
        "position": "relative"
      }
    }}>{`Accessible colour combinations`}<a parentName="h3" {...{
        "href": "#accessible-colour-combinations",
        "aria-label": "accessible colour combinations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>Here you can find some recommended colour combinations for text and other informative elements. The examples are sorted in order of their contrast ratio. For more examples of accessible colour combinations, see the <ExternalLink href="https://share.goabstract.com/28352b8b-9c51-4346-b249-ff062c5da561" mdxType="ExternalLink">Colour accessibility examples - Abstract collection</ExternalLink>.</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AA`}</strong>{` (contrast ratio at least `}<inlineCode parentName="li">{`4,5 : 1`}</inlineCode>{`) and `}<strong parentName="li">{`AAA`}</strong>{` (contrast ratio at least `}<inlineCode parentName="li">{`7 : 1`}</inlineCode>{`) means you can safely use the color combination on all text sizes`}</li>
      <li parentName="ul"><strong parentName="li">{`AA for large text`}</strong>{` (contrast ratio at least `}<inlineCode parentName="li">{`3 : 1`}</inlineCode>{`) means the text size should be at least 18 pt for regular weight or 14 pt for bold weight`}</li>
      <li parentName="ul"><strong parentName="li">{`Black 90`}</strong>{` is the default text colour in HDS`}</li>
    </ul>
    <h4 {...{
      "id": "brand-colour-combinations",
      "style": {
        "position": "relative"
      }
    }}>{`Brand colour combinations`}<a parentName="h4" {...{
        "href": "#brand-colour-combinations",
        "aria-label": "brand colour combinations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Combining a brand colour background with the correct text colour is important to maintain a high contrast.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour name (en)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Text colour token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour contrast (WCAG)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Engel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`14,3 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-engel)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Silver`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`13,1 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-silver)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`12 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-bus)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Summer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`11,1 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-summer)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fog`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`10 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-fog)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`9,4 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-copper)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suomenlinna`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`9,1 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-suomenlinna)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`7,1 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-gold)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Brick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`6,1 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-brick)" mdxType="ContrastExample">{`AA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Metro`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`5,2 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-metro)" mdxType="ContrastExample">{`AA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coat of Arms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`5 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-coat-of-arms)" mdxType="ContrastExample">{`AA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Spåra`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`4,6 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-tram)" mdxType="ContrastExample">{`AA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Combining brand colours with a text colour]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`For text and other informative elements on white background`}</strong>{` it is recommended to only use following brand colours:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour name (en)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour contrast (WCAG)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`12 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-bus)" background="var(--color-white)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Brick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`6,1 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-brick)" background="var(--color-white)" mdxType="ContrastExample">{`AA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coat of Arms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`5 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-coat-of-arms)" background="var(--color-white)" mdxType="ContrastExample">{`AA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`4,6 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-tram)" background="var(--color-white)" mdxType="ContrastExample">{`AA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Metro`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AA for large text `}<inlineCode parentName="td">{`3,3 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-metro)" background="var(--color-white)" mdxType="ContrastExample">{`A`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Using brand colours on a white background]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "grayscale-combinations",
      "style": {
        "position": "relative"
      }
    }}>{`Grayscale combinations`}<a parentName="h4" {...{
        "href": "#grayscale-combinations",
        "aria-label": "grayscale combinations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`When using grayscale tokens, be mindful of the contrast. Black tokens below `}<inlineCode parentName="p">{`--color-black-50`}</inlineCode>{` do not have sufficient contrast on white backgrounds. Lighter blacks can be used on darker backgrounds or as colour of disabled elements.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour name (en)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Text colour token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour contrast (WCAG)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example (background)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example (text)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`21 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-black)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black)" background="var(--color-white)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-90`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`17,4 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-black-90)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-white)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-80`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`12,6 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-black-80)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-80)" background="var(--color-white)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-70`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`8,6 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-black-70)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-70)" background="var(--color-white)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-60`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` (AAA for large text) `}<inlineCode parentName="td">{`5,7 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-black-60)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-60)" background="var(--color-white)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AA for large text `}<inlineCode parentName="td">{`4,3 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-black-50)" mdxType="ContrastExample">{`A`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-50)" background="var(--color-black)" mdxType="ContrastExample">{`A`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`6,1 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-black-40)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-40)" background="var(--color-black)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`8,2 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-black-30)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-30)" background="var(--color-black)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`10,8 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-black-20)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-20)" background="var(--color-black)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`13,8 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-black-10)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-10)" background="var(--color-black)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Black-5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`15,4 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-black-5)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-5)" background="var(--color-black)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`17,4 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-black-90)" background="var(--color-white)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
          <td parentName="tr" {...{
            "align": null
          }}><ContrastExample color="var(--color-white)" background="var(--color-black)" mdxType="ContrastExample">{`AAA`}</ContrastExample></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Grayscale contrast]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "focus-colour",
      "style": {
        "position": "relative"
      }
    }}>{`Focus colour`}<a parentName="h4" {...{
        "href": "#focus-colour",
        "aria-label": "focus colour permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS components use a custom focus colour styling instead of the default browser provided style. The default HDS focus style is accessible on light backgrounds. Since HDS does not offer dark mode, the focus style colour may require customising when using HDS components on darker backgrounds. The white focus colour is recommended if the default colour does not meet the contrast requirement. The WCAG requirement for user interface element contrast is `}<inlineCode parentName="p">{`3 : 1`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour name (en)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Background colour token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour contrast (WCAG)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coat of Arms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AA`}</strong>{` `}<inlineCode parentName="td">{`5 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><FocusExample color="var(--color-coat-of-arms)" background="var(--color-white)" mdxType="FocusExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color-black`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AAA`}</strong>{` `}<inlineCode parentName="td">{`21 : 1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><FocusExample color="var(--color-white)" background="var(--color-black)" mdxType="FocusExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 4:Focus colour contrast]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`HDS focus style colour can be customised to better fit the theme of the service. However, the implementor must always ensure that the following WCAG requirements are met:`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.w3.org/TR/WCAG21/#use-of-color" mdxType="ExternalLink">WCAG 1.4.1 Use of Color (Level A)</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.w3.org/TR/WCAG21/#focus-order" mdxType="ExternalLink">WCAG 2.4.3 Focus Order (Level A)</ExternalLink> (while designing components with multiple focusable elements)
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.w3.org/TR/WCAG21/#focus-visible" mdxType="ExternalLink">WCAG 2.4.7 Focus Visible (Level A)</ExternalLink>
      </li>
      <li parentName="ul">{`While not officially part of WCAG yet, HDS also follows `}<ExternalLink href="https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance-minimum.html" mdxType="ExternalLink">{`WCAG 2.4.11 Focus Visible (Enhanced)`}</ExternalLink>{`. Due to their draft status, these requirements may still change. But we still recommend following them for future-proofing.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      